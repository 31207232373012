import React , {useEffect,useState} from "react";
import venuesData from './venues.json'
import { Link } from 'react-router-dom';
import VenueCard from "./VenueCard";
import styled from 'styled-components';

function VenueList(){

const VenueListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
`;

    
    console.log("Venues ",venuesData)

    //declared empty list of venues
    const [venues,setVenues]= useState([])
   
    //mounting empty array in the end allow us to load it only once
    //If not in each render it will load
    useEffect(()=>{
        setVenues(venuesData);
    },[]); 
    
        console.log("VenuesX ",venuesData);
        return (
          <VenueListContainer>
            {venuesData.map((venue) => (
               
              <VenueCard key={venue.id} venue={venue} />
            ))}
           </VenueListContainer>
        );

    }

export default VenueList;