import React, { useState } from 'react';
import backgroundImage1 from '../images/Wedding-Hall.jpg';

const HeroSection = () => {
  const [location, setLocation] = useState('');
  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');
  const [guests, setGuests] = useState('2 Guests, 1 Room');

  const handleSearch = (e) => {
    e.preventDefault();
    // Implement search functionality here
    console.log('Searching for:', { location, checkIn, checkOut, guests });
  };

  return (
    <div className="relative h-[700px] md:h-[800px] bg-cover bg-center flex items-center justify-center" style={{backgroundImage: `url(${backgroundImage1})`}}>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative z-10 text-white text-center px-4">
        <br></br>
        <br></br>
        <br></br>

        <h1 className="text-4xl md:text-5xl font-bold mb-4">Find the Best Venues!</h1>
        <p className="text-xl mb-8">Yes! You are at the Right place</p>

        
        <br></br>
        <br></br>
        <br></br>
      
        <form onSubmit={handleSearch} className="bg-white rounded-lg shadow-lg overflow-hidden max-w-4xl mx-auto">
          <div className="flex flex-col md:flex-row">
            <div className="flex-1 border-b md:border-b-0 md:border-r border-gray-300">
              <input
                type="text"
                placeholder="Where to?"
                className="w-full px-4 py-3 text-gray-700 focus:outline-none"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
            <div className="flex-1 border-b md:border-b-0 md:border-r border-gray-300">
              <input
                type="date"
                placeholder="Check in"
                className="w-full px-4 py-3 text-gray-700 focus:outline-none"
                value={checkIn}
                onChange={(e) => setCheckIn(e.target.value)}
              />
            </div>
            <div className="flex-1 border-b md:border-b-0 md:border-r border-gray-300">
              <input
                type="date"
                placeholder="Check out"
                className="w-full px-4 py-3 text-gray-700 focus:outline-none"
                value={checkOut}
                onChange={(e) => setCheckOut(e.target.value)}
              />
            </div>
            <div className="flex-1 border-b md:border-b-0 md:border-r border-gray-300">
              <select
                className="w-full px-4 py-3 text-gray-700 focus:outline-none"
                value={guests}
                onChange={(e) => setGuests(e.target.value)}
              >
                <option>2 Guests, 1 Room</option>
                <option>1 Guest, 1 Room</option>
                <option>3 Guests, 1 Room</option>
                <option>4 Guests, 2 Rooms</option>
              </select>
            </div>
            <div className="flex-none">
              <button type="submit" className="w-full h-full px-6 py-3 bg-blue-600 text-white font-semibold hover:bg-blue-700 transition duration-300">
                Search
              </button>
            </div>
          </div>
        </form>
        <br></br>
      </div>
    </div>
  );
};

export default HeroSection;




