
import VenueList from './components/VenueList.js';
import Header from './components/Header.js';
import HeroSection from './components/HeroSection.js';
// import AboutSection from './components/AboutSection.js';
// import Footer from './components/Footer.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AddVenueForm from './components/AddVenueForm.js';
import React, { useState } from "react";
import LoginSignupPage from './components/LoginSignupPage.js';


function App() {

  const[isLoggedIn,setIsLoggedIn]=useState(false)

  return (

    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<>  <HeroSection /> <VenueList />  </>} />
        <Route path='/login' element={<LoginSignupPage setIsLoggedIn={setIsLoggedIn} />} />
        <Route path='/add-venue' element={<> <HeroSection /> <AddVenueForm  isLoggedIn={isLoggedIn} />  </> } />
      </Routes>
    </Router>

  );

}

export default App;