import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate} from 'react-router-dom'

const FormContainer = styled.form`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
`;

const Input = styled.input`
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 15px;
`;

const TextArea = styled.textarea`
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  height: 100px;
  margin-bottom: 15px;
`;

const Button = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const AddVenueForm = () => {

    const [formData, setFormData] = useState({
        VenueName: '',
        OwnerId: '',
        Location: '',
        PinCode: '',
        About: ''
    });


    const handleChange=(f)=>{

        setFormData({...formData,[f.target.name]: f.target.value })

    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        console.log("Inside the Add Venue Submit" ,e);

        try {
            const response = await fetch('http://localhost:4100/venue/save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                console.log("VenueData Saved Successfully");
                alert('Venue added successfully!');
            } else {
                console.log("Error While Adding Venue ", response.statusText);
            }
        } catch (error) {
            console.error('Error adding venue:', error);
        }
    }

    return (

        <FormContainer onSubmit={handleSubmit}>
            <Label htmlFor="venuename">VenueName:</Label>
            <Input type="text" id="venuename" onChange={handleChange} name="VenueName" />

            <Label htmlFor="ownerid">OwnerId:</Label>
            <Input type="text" id="ownerid" onChange={handleChange} name="OwnerId" />

            <Label htmlFor="location">Location:</Label>
            <Input type="text" id="location" onChange={handleChange} name="Location" />

            <Label htmlFor="pincode">PinCode:</Label>
            <Input type="text" id="pincode"  onChange={handleChange} name="PinCode" />

            <Label htmlFor="about">About:</Label>
            <TextArea id="about" onChange={handleChange} name="About" />

            <Button type="submit">Add Venue</Button>
        </FormContainer>
    );
};

export default AddVenueForm;